import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import TrustBox from "../../components/Trustbox";
import ReactGA from "react-ga4";
import axios from "axios";
import LanguageCurrencyChanger from "../../components/header/sub-components/LanguageCurrencyChanger";
import LanguageChangerFooter from "../../components/header/sub-components/LanguageChangerFooter";
import {useTranslation} from "react-i18next";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  extraFooterClass,
  sideMenu,
  dispatch
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [landingPages, setLandingsPages] = useState(null);
  const ref = React.useRef(null);
  const { t, i18n } = useTranslation();
  const [origin, setOrigin] = useState("fr")

  useEffect(() => {
    setOrigin(i18n.language)
  }, [])

  const getLandingPages = () => {
    let query = 'https://public-front-api.therunningcollective.fr/landing-pages'
    axios.get(query, null)
        .then((res) => {
          setLandingsPages(res.data)
        })
        .catch((err) => {
          console.log(err);
        })
  };

  useEffect(() => {
    getLandingPages()
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer
      className={`footer-area ${
        backgroundColorClass ? backgroundColorClass : ""
      } ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${extraFooterClass ? extraFooterClass : ""} ${
        spaceLeftClass ? spaceLeftClass : ""
      } ${spaceRightClass ? spaceRightClass : ""}`}
    >
      <div className={"container-fluid"}>
        <div className="row">
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            {/* footer copyright */}
            <FooterCopyright
              spaceBottomClass="mb-30"
            />
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{t('enterprise')}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  {
                    i18n.language == "fr" || i18n.language == "be" ?
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/games"}>{t('games')}</Link>
                        </li>
                        : null
                  }
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>{t('whoarewe')}</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/comment-ca-marche"}>{t('howdoestitwork')}</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/pace"}>{t('pacecalculator')}</Link>
                  </li>
                  <li>
                    <a href="mailto:shop@therunningcollective.fr?subject=Demande d'informations">
                      {t('contactus')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-50"
              }`}
            >
              <div className="footer-title">
                <h3>{t('legal')}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <a href="javascript:openAxeptioCookies()">
                      {t('modifycookies')}
                    </a>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/conditions-generales-utilisation"}>{t('cgu')}</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/conditions-generales-ventes"}>
                      {t('cgv')}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/politique-protection-donnees"}>{t('persoData')}</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/paiement-securise"}>{t('securityPayments')}</Link>
                  </li>

                  <li>
                    <Link to={process.env.PUBLIC_URL + "/mentions-legales"}>{t('legal')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {
            landingPages && landingPages.length > 0 && (i18n.language == "fr" || i18n.language == "be") ?
                <div
                    className={`${
                        sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-4"
                    }`}
                >
                  <div
                      className={`${
                          sideMenu
                              ? "footer-widget mb-30 ml-145"
                              : "footer-widget mb-30 ml-75"
                      }`}
                  >

                    <div className="footer-title">
                      <h3>{t('offers')}</h3>
                    </div>
                    <div className="footer-list">
                      <ul>
                        {
                          landingPages.map(page => {
                            return (
                                <li>
                                  <a
                                      href={process.env.PUBLIC_URL + "/offers/"+ page.slug}
                                      rel="noopener noreferrer"
                                  >
                                    {page.title}
                                  </a>
                                </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                : null
          }

          <div
              className={`${
                  sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-4"
              }`}
          >
            <div
                className={`${
                    sideMenu
                        ? "footer-widget mb-30 ml-145"
                        : "footer-widget mb-30 ml-75"
                }`}
            >
              <div className="footer-title">
                <h3>{t('followus')}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <a
                        href="https://www.facebook.com/temporunclub/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://www.instagram.com/temporunclub/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://www.linkedin.com/company/the-running-collective/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://vm.tiktok.com/ZMRvvqYeJ/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      TikTok
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://twitter.com/trc_run"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://www.youtube.com/channel/UCv6qkHY7_c5fq6xZlZCi8Zg"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            {/* footer newsletter */}
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-10"
              sideMenu={sideMenu}
            />
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        style={{bottom: "20px"}}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
