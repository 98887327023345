import PropTypes from "prop-types";
import React from "react";
import {AiOutlineRight} from "react-icons/all";
import {Col, Row} from "react-bootstrap";
import {isMobileOnly} from "react-device-detect";
import ReactGA from "react-ga4";

const HeroSliderNineSingle = ({ data, sliderClass, marginTop, isSide }) => {

  const onRedirect = (data) => {
    ReactGA.event({
      category: 'Banner',
      action: 'Redirect to ' + data.title
    });
    ReactGA.event({
        category: 'Banner',
        action: 'Redirect to ' + data.title + " for url : " + data.url
    });
    data.url.includes('https://app.therunningcollective.fr') ? window.location = extractUrl(data.url) : window.open(data.url, '_blank')
  } 

  return (
      <div
          className={`single-slider-2 slider-height-1 d-flex align-items-center slider-height-res bg-img ${
              sliderClass ? sliderClass : ""
          }`}
          onClick={() => {onRedirect(data)}}
          style={{
            cursor: "pointer",
            height: isSide ? "100%" : "",
            width: "100%",
            paddingBottom: marginTop && marginTop !== "" ? "20px": "",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, ${data.contrast != "" ? data.contrast : "0.3"}),rgba(0, 0, 0, ${data.contrast != "" ? data.contrast : "0.3"})) , url(${(isMobileOnly ? data.image : data.desktopImage != "" ? data.desktopImage : data.image)})`
          }}
      >
        <div className="container" style={{marginTop: marginTop && marginTop != "" ? marginTop : "180px"}}>
          <div className="row">
            <div className={isSide ? "col-xl-11 col-lg-11 col-md-7 ml-auto" : "col-xl-6 col-lg-6 col-md-7 ml-auto"}>
              <div className="slider-content-2 slider-animated-1" style={{
                paddingLeft: isMobileOnly ? " 15px" : "none",
                paddingRight: isMobileOnly ? " 15px" : "none",

              }}>
                <div className="slider-h3 animated" style={{color: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}}>
                  <span className={"trait"} style={{backgroundColor: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}}/>
                  {data.title}
                </div>
                <hr style={{
                  backgroundColor: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black',
                  marginTop: "10px", marginBottom: "10px"}}/>
                <Row>
                  <Col xs={ isSide ? 8 : 10}>
                  <span
                      className="slider-h1 animated"
                      style={{color: data.textColor ? rgbToHex(data.textColor.r, data.textColor.g, data.textColor.b) : 'black'}}
                      dangerouslySetInnerHTML={{ __html: data.subtitle }}
                  ></span>
                  </Col>
                  <Col xs={isSide ? 4 : 2}>
                    <div className="slider-btn btn-hover" style={{textAlign: isSide ? "center" : "right"}}>
                      <a
                          className="animated">
                        <AiOutlineRight size="1rem"/>
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

function extractUrl(url){
  const newURL = url.replace('https://app.therunningcollective.fr', process.env.PUBLIC_URL);
  return newURL
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

HeroSliderNineSingle.propTypes = {
  data: PropTypes.object,
  sliderClass: PropTypes.string
};

export default HeroSliderNineSingle;
