import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {setWithExpiry} from "../pages/home/HomeFashionTwo";
import {useTranslation} from "react-i18next";

export function LanguagePopup({show, setShow, actualCountry, expectedCountry, actualCountryC}) {
    const { t } = useTranslation()
    const handleClose = () => {
        setShow(false);
        setWithExpiry('trc-lang-popup', 'true', 86400000)
    }

    return (
        <Modal show={show} onHide={handleClose} size="sm">
            <Modal.Header closeButton  style={{display: "none"}}>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{fontSize:"18px", textAlign:"center"}}>
                    {t('poplang1')} {actualCountryC}.<br/>
                    {t('poplang2')}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} style={{borderRadius: "50px"}}>
                    {parseLang(expectedCountry)}
                </Button>
                <Button variant="primary" onClick={() =>
                    window.location = parseCountry(actualCountry)
                } style={{backgroundColor: "black", borderColor: "black", borderRadius: "50px"}}>
                    {actualCountryC}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function parseLang(lang) {
    switch (lang) {
        case "fr":
            return "France"
        case "be":
            return "Belgique"
        case "it":
            return "Italia"
        case "es":
            return "España"
        case "de":
            return "Deutschland"
        case "pt":
            return "Portugal"
        case "co.uk":
            return "United-Kingdom"
        default:
            return "France"
    }
}

function parseCountry(lang) {
    let url = "https://app.therunningcollective."
    switch (lang) {
        case "fr":
            return url + "fr"
        case "be":
            return url + "be"
        case "it":
            return url + "it"
        case "es":
            return url + "es"
        case "de":
            return url + "de"
        case "pt":
            return url + "pt"
        case "co.uk":
            return url + "co.uk"
        default:
            return "France"
    }
}