import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Swiper from "react-id-swiper";
import axios from "axios";
import HeroSliderNineSingle from "../../components/hero-slider/HeroSliderNineSingle";

const HeroSliderNine = ({ spaceLeftClass, spaceRightClass, shopType, origin }) => {

  const [avalaibleSlides, setAvalaibleSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSlidesData = () => {
    if (origin && origin == "be"){
      origin = "fr"
    }
    let query = 'https://public-front-api.therunningcollective.fr/home-appearance?lang='+origin+'&shopType='+shopType+'&appearanceType=/appearance/home/slider'

    axios
        .get(query)
        .then((res) => {
          setAvalaibleSlides(res.data);
          setLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
  };

  useEffect(() => {
    fetchSlidesData();
  }, []);

  const params = {
    effect: "fade",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    )
  };
  return (
    <div
      className={`slider-area ${spaceLeftClass ? spaceLeftClass : ""} ${
        spaceRightClass ? spaceRightClass : ""
      }`}
    >
      {!loading ?
          <div className="slider-active nav-style-1">
            <Swiper {...params}>
              {avalaibleSlides.length > 0 &&
              avalaibleSlides.map((single, key) => {
                return (
                    <HeroSliderNineSingle
                        data={single}
                        key={key}
                        isSide={false}
                        sliderClass="swiper-slide"
                    />
                );
              })}
            </Swiper>
          </div>
      :
          null
      }

    </div>
  );
};

HeroSliderNine.propTypes = {
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
  shopType: PropTypes.string,
  origin: PropTypes.string
};

export default HeroSliderNine;
