import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { a } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import axios from "axios";
import {useTranslation} from "react-i18next";
import moment from "moment";

const MobileNavMenu = ({ strings }) => {
  const [user, setUser] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem('trc-client-token'))
    if (token) {
      setUser(token.user)
      //getProfile(token);

    }
  }, []);

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/"}>{t('home')}</a>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
            {" "}
            {t('men')}
          </a>
          <ul className="sub-menu">
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                {t('shoes')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?shopType=athletisme&page=1"}>
                    {t('spikes')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                    {t('road')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=trail&page=1"}>
                    {t('trail')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                    {t('thongs')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                    {t('allshoes')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                {t('spikes')}
              </a>
                  <ul className="sub-menu">
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/spikes?category=Cross&page=1"}>
                        {t('cross')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/spikes?category=Demi-fond&page=1"}>
                        {t('middledistance')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/spikes?category=Sprint&page=1"}>
                        {t('sprint')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/spikes?category=Autres&page=1"}>
                        {t('others')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                        {t('allspikes')}
                      </a>
                    </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                {t('apparel')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Débardeurs&page=1"}>
                    {t('singlet')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Tee-shirts&page=1"}>
                    {t('teeshirt')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Vestes&page=1"}>
                    {t('hoodies')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Cuissards&page=1"}>
                    {t('halftigh')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Shorts&page=1"}>
                    {t('short')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Collants&page=1"}>
                    {t('longtight')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Equipe%20de%20France&page=1"}>
                    {t('frenchteam')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                    {t('allproducts')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                {t('accessories')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                    {t('hat')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                    {t('socks')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?genders=Men&category=Gants&page=1"}>
                    {t('gloves')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                    {t('gps')}
                  </a>
                </li>
                <li className="menu-item-has-children">
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                    {t('food')}
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Boissons%20énergétiques&page=1"}>
                      {t('fooddrink')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Gels%20énergétiques&page=1"}>
                      {t('foodgel')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Barres%20énergétiques&page=1"}>
                      {t('foodbar')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Packs&page=1"}>
                      {t('foodpack')}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                        {t('allfood')}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                    {t('electronic')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                    {t('bag')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                    {t('allaccesories')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                {t('food')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Boissons%20énergétiques&page=1"}>
                   {t('fooddrink')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Gels%20énergétiques&page=1"}>
                   {t('foodgel')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Barres%20énergétiques&page=1"}>
                  {t('foodbar')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Packs&page=1"}>
                  {t('foodpack')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                    {t('allfood')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                {t('electronic')}
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/vouchers?page=1"}>
                {t('vouchers')}
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
                {t('brands')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=adidas&page=1"}>
                    adidas
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Asics&page=1"}>
                    Asics
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Hoka%20One%20One&page=1"}>
                    Hoka One One
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=New%20Balance&page=1"}>
                    New Balance
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Nike&page=1"}>
                    Nike
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=On%20Running&page=1"}>
                    On Running
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Puma&page=1"}>
                    Puma
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Saucony&page=1"}>
                    Saucony
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
            {" "}
            {t('women')}
          </a>
          <ul className="sub-menu">
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                {t('shoes')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                    {t('spikes')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                    {t('road')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=trail&page=1"}>
                    {t('trail')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                    {t('thongs')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                    {t('allshoes')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                {t('spikes')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?category=Cross&page=1"}>
                    {t('cross')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?category=Demi-fond&page=1"}>
                    {t('middledistance')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?category=Sprint&page=1"}>
                    {t('sprint')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?category=Autres&page=1"}>
                    {t('others')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                    {t('allspikes')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&page=1"}>
                {t('apparel')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Brassières&page=1"}>
                    {t('bra')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Débardeurs&page=1"}>
                    {t('singlet')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Tee-shirts&page=1"}>
                    {t('teeshirt')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Vestes&page=1"}>
                    {t('hoodies')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Shorts&page=1"}>
                    {t('short')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Collants&page=1"}>
                    {t('longtight')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Equipe%20de%20France&page=1"}>
                    {t('frenchteam')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&page=1"}>
                    {t('allproducts')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                {t('accessories')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                    {t('hat')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                    {t('socks')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?genders=Women&category=Gants&page=1"}>
                    {t('gloves')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                    {t('gps')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                    {t('electronic')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                    {t('bag')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                    {t('allaccesories')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                {t('food')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Boissons%20énergétiques&page=1"}>
                   {t('fooddrink')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Gels%20énergétiques&page=1"}>
                   {t('foodgel')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Barres%20énergétiques&page=1"}>
                  {t('foodbar')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Packs&page=1"}>
                  {t('foodpack')}
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                    {t('allfood')}
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                {t('electronic')}
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products/vouchers?page=1"}>
                {t('vouchers')}
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
                {t('brands')}
              </a>
              <ul className="sub-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=adidas&page=1"}>
                    adidas
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Asics&page=1"}>
                    Asics
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Hoka%20One%20One&page=1"}>
                    Hoka One One
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=New%20Balance&page=1"}>
                    New Balance
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Nike&page=1"}>
                    Nike
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=On%20Running&page=1"}>
                    On Running
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Puma&page=1"}>
                    Puma
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Saucony&page=1"}>
                    Saucony
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
            {t('accessories')}
          </a>
          <ul className="sub-menu">
            <li>
              <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                {t('hat')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                {t('socks')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/accessories?category=Gants&page=1"}>
                {t('gloves')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/accessories?category=Lunettes%20de%20vitesse&page=1"}>
                {t('speedglasses')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                {t('bag')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                {t('allaccesories')}
              </a>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
            {t('food')}
          </a>
          <ul className="sub-menu">
            <li>
                <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Boissons%20énergétiques&page=1"}>
                  {t('fooddrink')}
                </a>
            </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Gels%20énergétiques&page=1"}>
                  {t('foodgel')}
                </a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Barres%20énergétiques&page=1"}>
                {t('foodbar')}
                </a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Packs&page=1"}>
                {t('foodpack')}
                </a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                  {t('allfood')}
                </a>
              </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
            {t('electronic')}
          </a>
          <ul className="sub-menu">
            <li>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Capteur%20de%20puissance&page=1"}>
                {t('powermeter')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Ceinture%20cardiaque&page=1"}>
                {t('hrsensor')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Electrostimulation&page=1"}>
                {t('electrostimulation')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Lampes%20frontales&page=1"}>
                {t('headlamps')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                {t('gps')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Pistolets%20de%20massage&page=1"}>
                {t('massagegun')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?category=Pressothérapie&page=1"}>
                {t('pressotherapy')}
              </a>
              <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                {t('allelectronic')}
              </a>
            </li>
          </ul>
        </li>

        {
          i18n.language == "fr" ?
              <li className="menu-item-has-children">
                <a href={"https://blog.therunningcollective.fr"}>
                  {t('blog')}
                </a>
              </li>
              :
              null
        }
        {
          i18n.language == "fr" ?
              <li className="menu-item-has-children">
                <a href={process.env.PUBLIC_URL + "/new-podcasts"}>
                  {t('podcasts')}
                </a>
              </li>
              :
              null
        }
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/races?page=1&startDate="+
              moment().locale('fr').format('L')+"&endDate="+
              moment().add(1, 'M').locale('fr').format('L')}>
            {t('races')}
          </a>
          <ul className="sub-menu">
            <li>
              <a
                  href={process.env.PUBLIC_URL + "/races?type=athletisme&page=1&startDate="+
                      moment().locale('fr').format('L')+"&endDate="+
                      moment().add(1, 'M').locale('fr').format('L')}
                  rel="noopener noreferrer"
              >
                Athlétisme
              </a>
            </li>
            <li>
              <a
                  href={process.env.PUBLIC_URL + "/races?type=running&page=1&startDate="+
                      moment().locale('fr').format('L')+"&endDate="+
                      moment().add(1, 'M').locale('fr').format('L')}
                  rel="noopener noreferrer"
              >
                Running
              </a>
            </li>
            <li>
              <a
                  href={process.env.PUBLIC_URL + "/races?type=trail&page=1&startDate="+
                      moment().locale('fr').format('L')+"&endDate="+
                      moment().add(1, 'M').locale('fr').format('L')}
                  rel="noopener noreferrer"
              >
                Trail
              </a>
            </li>
            <li>
              <a
                  href={process.env.PUBLIC_URL + "/races?type=triathlon&page=1&startDate="+
                      moment().locale('fr').format('L')+"&endDate="+
                      moment().add(1, 'M').locale('fr').format('L')}
                  rel="noopener noreferrer"
              >
                Triathlon
              </a>
            </li>
            <li>
              <a
                  href={process.env.PUBLIC_URL + "/races?page=1&startDate="+
                      moment().locale('fr').format('L')+"&endDate="+
                      moment().add(1, 'M').locale('fr').format('L')}
                  rel="noopener noreferrer"
              >
                Toutes les courses
              </a>
            </li>
          </ul>
        </li>

        <li className="menu-item-has-children">
          <a href="">
            {t('socialmedias')}
          </a>
          <ul className="sub-menu">
            <li>
              <a
                  href="https://www.instagram.com/temporunclub/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                  href="https://www.facebook.com/temporunclub/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                  href="https://www.linkedin.com/company/the-running-collective/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                  href="https://vm.tiktok.com/ZMRvvqYeJ/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Tiktok
              </a>
            </li>
            <li>
              <a
                  href="https://twitter.com/trc_run"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                  href="https://www.youtube.com/channel/UCv6qkHY7_c5fq6xZlZCi8Zg"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Youtube
              </a>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/games"}>{t('games')}</a>
        </li>
        <li className="menu-item-has-children">
          <a href={process.env.PUBLIC_URL + "/pace"}>{t('pacecalculator')}</a>
        </li>
      </ul>
      {user ?
          <ul>
            <li>
              <hr/>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/my-account"}>
                {t('myaccount')}
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/logout"}>
                {t('logout')}
              </a>
            </li>
          </ul>

          :
          <ul>
            <li>
              <hr/>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/login-register"}>{t('login')}</a>
            </li>
          </ul>

      }
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object
};

export default multilanguage(MobileNavMenu);
